<template>
  <div style="position: relative">
    <div style="position: absolute; top: 6vh; left: 15vw; width: 50%">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="微信号:" prop="tel">
          <el-input v-model="form.wx"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="微信二维码:" prop="email">
          <img src="@/assets/quban.jpg" width="200" alt="">
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        userName: "",
        realName: "",
        wx: "Qu_Ban",
        email: "quban6001@163.com",
      },

    };
  },
  mounted() {
  },
  methods: {


  },
};
</script>
